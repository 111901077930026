var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "widget-menu-panel",
      attrs: { id: "widget-menu" },
    },
    _vm._l(_vm.menuList, function (item, itemIndex) {
      return _c(
        "li",
        {
          key: item.name + itemIndex,
          staticClass: "widget-menu-item",
          on: {
            click: function (e) {
              return item.handler(_vm.selectText, e)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.name) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }